import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
} from 'ducks/deviceActions'
import Navbar from 'components/global/Navbar'
import MaskedInput from 'react-text-mask'
import AccessCode from 'components/listings/AccessCode'

const maskBuilder = (v) => {
  if (!v || v.length == 0) return ['0', /\d/, /\d/, /\d/]
  return v.length >= 4 && v.length <= 5 ? [/\d/, /\d/, /\d/, /\d/, /\d/] : ''
}

class AccessCodesPage extends Component {
  state = {
    keyValue: '',
    expTime: '',
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.getDeviceAccessCodes(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', acceptedFile: null }
    }

    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.addAccessCode({
      deviceUuid: this.props.match.params.id,
      key: this.state.keyValue,
      expTime: this.state.expTime,
    })
  }

  handleDeleteClick = (keyValue) => {
    this.props.deleteAccessCode({
      deviceUuid: this.props.match.params.id,
      key: keyValue,
    })
  }

  render() {
    const { match, accessCodes } = this.props

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!accessCodes) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ marginTop: '4.625rem' }}>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group widths="equal">
              <Form.Input
                label="Код"
                placeholder="четырех-/пятизначное число"
                children={
                  <Input
                    name="keyValue"
                    value={this.state.keyValue}
                    onChange={this.onChange}
                    // mask={['0', /\d/, /\d/, /\d/]}
                    mask={maskBuilder(this.state.keyValue)}
                    placeholder="четырех-/пятизначное число"
                  />
                }
              />

              <Form.Input
                label="Срок действия (опционально)"
                name="expTime"
                children={
                  <MaskedInput
                    name="expTime"
                    value={this.state.expTime}
                    onChange={this.onChange}
                    mask={[
                      /\d/,
                      /\d/,
                      '/',
                      /[0-1]/,
                      /[0-9]/,
                      '/',
                      '2',
                      '0',
                      /\d/,
                      /\d/,
                      ' ',
                      /\d/,
                      /\d/,
                      ':',
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="01/09/2020 12:45"
                  />
                }
              />
            </Form.Group>

            <Form.Button type="submit" color="olive" fluid>
              Добавить
            </Form.Button>
          </Form>

          <List selection size="big" style={{ marginTop: '4rem' }}>
            {accessCodes.length < 1 && (
              <Message>
                <Message.Header>Отсутствуют коды доступа</Message.Header>
              </Message>
            )}
            {accessCodes.map(({ value, exp_time, uuid }) => (
              <AccessCode
                key={value}
                value={value}
                exp_time={exp_time}
                onClick={() => this.handleDeleteClick(uuid)}
              />
            ))}
          </List>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  success: deviceActions.success,
  error: deviceActions.error,
  accessCodes: deviceActions.accessCodes,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  clearDeviceStatus,
  getDeviceAccessCodes,
  addAccessCode,
  deleteAccessCode,
})(AccessCodesPage)
