import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import {
  Label,
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Input,
} from 'semantic-ui-react'
import Slider, { createSliderWithTooltip, Range } from 'rc-slider'
import { useSelector, useDispatch } from 'react-redux'
import { blue } from 'theme'
import 'theme/slider.css'
import { updateGeneralFlatsInfo } from 'ducks/deviceActions'
import useInput from 'hooks/useInput'
import useIsMount from 'hooks/useIsMount'

const center = {
  display: 'flex',
  justifyContent: 'center',
}

const TimeoutSettings = ({ style = {}, ...props }) => {
  const isMount = useIsMount()
  const dispatch = useDispatch()

  const device = useSelector(({ device }) => device && device.shadowDevice)
  const baseConfig = device && device.properties && device.properties.base
  const [modified, setModified] = useState(false)

  const { bind: bindSpeak, value: flatSpeak } = useInput(
    baseConfig.timeout_flat_speak / 1000,
  )
  const { bind: bindCall, value: flatCall } = useInput(
    baseConfig.timeout_flat_call / 1000,
  )
  useEffect(() => {
    if (!isMount) setModified(true)
  }, [flatCall, flatSpeak])

  const handleSubmit = () => {
    dispatch(
      updateGeneralFlatsInfo({
        id: device.device_id,
        timeout_flat_speak: flatSpeak * 1000,
        timeout_flat_call: flatCall * 1000,
      }),
    )
    setModified(false)
  }

  return (
    <Segment
      style={{ display: 'flex', flexDirection: 'column', ...style }}
      {...props}
    >
      <Header>Звонки</Header>

      <Form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Form.Field>
          <label>Длительность дозвона</label>

          <Input
            placeholder="время в секундах"
            size="mini"
            type="number"
            labelPosition="right"
            label={{ basic: true, content: 'сек' }}
            {...bindCall}
          ></Input>
        </Form.Field>
        <Form.Field>
          <label>Продолжительность звонка</label>
          <Input
            placeholder="время в секундах"
            size="mini"
            type="number"
            labelPosition="right"
            label={{ basic: true, content: 'сек' }}
            {...bindSpeak}
          ></Input>
        </Form.Field>

        <Form.Button
          circular
          style={{
            margin: '1rem auto',
            marginBottom: 0,
            marginTop: 'auto',
          }}
          color="black"
          onClick={handleSubmit}
          className="update-button"
          disabled={!modified}
        >
          Обновить
        </Form.Button>
      </Form>
    </Segment>
  )
}

// const mapStateToProps = ({ device }) => ({
//   data:
//     device &&
//     device.shadowDevice &&
//     device.shadowDevice.properties &&
//     device.shadowDevice.properties.base,
//   id: device && device.shadowDevice && device.shadowDevice.device_id,
// })

// export default connect(mapStateToProps, { updateGeneralFlatsInfo })(
//   FlatsGeneral,
// )

export default TimeoutSettings
