import { all, fork, put, takeLatest, call, spawn } from 'redux-saga/effects'
import Api from 'api/sputnikApi'
import { GET_NET_INFO_SUCCESS } from './deviceActions'

// types
const GET_DEVICE_INFO_REQUEST = 'device/GET_DEVICE_INFO_REQUEST'
const GET_DEVICE_INFO_SUCCESS = 'device/GET_DEVICE_INFO_SUCCESS'
const GET_DEVICE_INFO_FAILURE = 'device/GET_DEVICE_INFO_FAILURE'

const GET_DEVICE_LOGS_REQUEST = 'device/GET_DEVICE_LOGS_REQUEST'
const GET_DEVICE_LOGS_SUCCESS = 'device/GET_DEVICE_LOGS_SUCCESS'
const GET_DEVICE_LOGS_FAILURE = 'device/GET_DEVICE_LOGS_FAILURE'

const GET_SOUNDS_SUCCESS = 'device/GET_SOUNDS_SUCCESS'
const GET_SOUNDS_FAILURE = 'device/GET_SOUNDS_FAILURE'

const GET_DEVICE_INFO_SHADOW_REQUEST = 'device/GET_DEVICE_INFO_SHADOW_REQUEST'
const GET_DEVICE_INFO_SHADOW_SUCCESS = 'device/GET_DEVICE_INFO_SHADOW_SUCCESS'
const GET_DEVICE_INFO_SHADOW_FAILURE = 'device/GET_DEVICE_INFO_SHADOW_FAILURE'

const CLEAR_CURRENT_DEVICE_INFO_REQUEST =
  'device/CLEAR_CURRENT_DEVICE_INFO_REQUEST'
const CLEAR_CURRENT_DEVICE_INFO = 'device/CLEAR_CURRENT_DEVICE_INFO'

const initialState = {
  fetched: false,
  currentDevice: {},
  shadowDevice: {},
  logs: { isFetching: false },
  error: '',
  sounds: [],
}

// reducer
export default function device(state = initialState, action) {
  switch (action.type) {
    case GET_DEVICE_INFO_SUCCESS:
      return {
        ...state,
        currentDevice: action.payload,
        fetched: true,
        error: '',
      }
    case GET_DEVICE_INFO_FAILURE:
      return { ...state, error: action.payload, fetched: false }
    case GET_DEVICE_INFO_SHADOW_SUCCESS:
      return {
        ...state,
        shadowDevice: action.payload,
        fetched: true,
        error: '',
      }
    case GET_DEVICE_INFO_SHADOW_FAILURE:
      return { ...state, error: action.payload, fetched: false }
    case GET_DEVICE_LOGS_REQUEST:
      return { ...state, logs: { ...state.logs, isFetching: true } }
    case GET_DEVICE_LOGS_SUCCESS:
      return {
        ...state,
        logs: {
          total_pages: action.payload.pages_count,
          collection: action.payload.data,
          isFetching: false,
        },
        error: '',
      }
    case GET_DEVICE_LOGS_FAILURE:
      return { ...state, logs: { isFetching: false }, error: action.payload }
    case GET_SOUNDS_SUCCESS:
      return { ...state, sounds: action.payload, error: '' }
    case GET_SOUNDS_FAILURE:
      return { ...state, success: '', error: action.payload }
    case CLEAR_CURRENT_DEVICE_INFO:
      return initialState
    case 'deviceActions/CLEAR_DEVICE_STATUS':
      return { ...state, error: '', success: '' }
    default:
      return state
  }
}

// action creators
export const getDeviceInfo = (id, history) => ({
  type: GET_DEVICE_INFO_REQUEST,
  payload: {
    id,
    history,
  },
})

export const clearCurrentDeviceInfo = () => ({
  type: CLEAR_CURRENT_DEVICE_INFO_REQUEST,
})

export const getDeviceLogs = (id, qty, page) => ({
  type: GET_DEVICE_LOGS_REQUEST,
  payload: {
    id,
    qty,
    page,
  },
})

export const getDeviceInfoShadow = (payload) => ({
  type: GET_DEVICE_INFO_SHADOW_REQUEST,
  payload,
})

// sagas
function* getDeviceInfoWorker({ type, payload }) {
  try {
    const { id } = payload
    const response = yield call(Api.getDeviceInfo, id)
    const info = response.data
    yield put({ type: GET_DEVICE_INFO_SUCCESS, payload: info })
    return Promise.resolve()
  } catch (err) {
    const { history } = payload
    yield put({
      type: GET_DEVICE_INFO_FAILURE,
      payload:
        'При получении информации об устройстве произошла ошибка. Такого устройства не существует.',
    })
    history.push('/')
    return Promise.reject()
  }
}

function* clearCurrentDeviceInfoWorker({ type, payload }) {
  yield put({ type: CLEAR_CURRENT_DEVICE_INFO })
}

function* getDeviceLogsWorker({ type, payload }) {
  try {
    const response = yield call(Api.getDeviceLogs, payload)
    const device = response.data
    yield put({ type: GET_DEVICE_LOGS_SUCCESS, payload: device })
  } catch (err) {
    yield put({ type: GET_DEVICE_LOGS_FAILURE, payload: err.message })
  }
}

export function* getDeviceInfoShadowWorker({ type, payload }) {
  try {
    const response = yield call(Api.getDeviceInfoShadow, payload)
    const data = response.data
    yield put({ type: GET_DEVICE_INFO_SHADOW_SUCCESS, payload: data })
    return Promise.resolve()
  } catch (err) {
    yield put({
      type: GET_DEVICE_INFO_SHADOW_FAILURE,
    })
    return Promise.reject()
  }
}

function* watchGetDeviceInfo() {
  yield takeLatest(GET_DEVICE_INFO_REQUEST, getDeviceInfoWorker)
}

function* watchClearCurrentDeviceInfo() {
  yield takeLatest(
    CLEAR_CURRENT_DEVICE_INFO_REQUEST,
    clearCurrentDeviceInfoWorker,
  )
}

function* watchGetDeviceLogs() {
  yield takeLatest(GET_DEVICE_LOGS_REQUEST, getDeviceLogsWorker)
}

function* watchGetDeviceInfoShadow() {
  yield takeLatest(GET_DEVICE_INFO_SHADOW_REQUEST, getDeviceInfoShadowWorker)
}

export function* deviceSagas() {
  yield all([
    fork(watchGetDeviceInfo),
    spawn(watchGetDeviceLogs),
    spawn(watchGetDeviceInfoShadow),
    spawn(watchClearCurrentDeviceInfo),
  ])
}
