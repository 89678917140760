import React, { useRef, useEffect, useState } from 'react'
import MindMapHelper from './mindmaphelper'
import * as d3 from 'd3'
import useMeasure from '../../hooks/useMeasure'
import { yellow, green, red } from 'theme.js'
import { useHistory } from 'react-router-dom'

// export default function MindMap({ data }) {
//   const [wrapperRef, bounds] = useMeasure()
//   const mapRef = useRef()
//   const history = useHistory()

//   const width = 932
//   const radius = width / 2

//   const dotSize = 5

//   function autoBox() {
//     const { x, y, width, height } = this.getBBox()

//     return [x, y, width, height]
//     // return [bounds.x, bounds.y, bounds.width, bounds.height]
//     // return [0, 0, bounds.width, 500]
//   }

//   function initMindMap(ref) {
//     const tree = d3
//       .tree()
//       .size([2 * Math.PI, radius])
//       .separation((a, b) => (a.parent == b.parent ? 1 : 2) / a.depth)

//     const root = tree(
//       d3
//         .hierarchy(data)
//         .sort((a, b) =>
//           d3.ascending(a.data.serial_number, b.data.serial_number),
//         ),
//     )

//     // const svg = d3
//     //   .create('svg')

//     // Create Event Handlers for mouse
//     function handleMouseOver(d, i) {
//       // Add interactivity

//       // Use D3 to select element, change color and size
//       d3.select(this).attr('r', dotSize * 1.5)
//     }

//     function handleMouseOut(d, i) {
//       // Use D3 to select element, change color back to normal
//       d3.select(this).attr('r', dotSize)
//     }

//     function handleMouseClick({ data }, y) {
//       console.log(data.device_id)
//       history.push(`/${data.device_id}`)
//       // console.log(this)
//       // console.log(d)
//       // const el = d3.select(this)
//       // console.log(el)
//       // el.attr('r', 5)
//     }

//     const svg = d3
//       .select(ref)
//       .style('max-width', '100%')
//       .style('font', '10px "Futura New"')
//     // .style('font-size', '18px')

//     const link = svg
//       .append('g')
//       .attr('fill', 'none')
//       .attr('stroke', d => {
//         return '#555'
//       })
//       .attr('stroke-opacity', 0.4)
//       .attr('stroke-width', 1.5)
//       .selectAll('path')
//       .data(root.links())
//       .join('path')
//       .attr(
//         'd',
//         d3
//           .linkRadial()
//           .angle(d => d.x)
//           .radius(d => d.y),
//       )

//     const node = svg
//       .append('g')
//       .attr('stroke-linejoin', 'round')
//       .attr('stroke-width', 3)
//       .selectAll('g')
//       .data(root.descendants().reverse())
//       .join('g')
//       .attr(
//         'transform',
//         d => `
//         rotate(${(d.x * 180) / Math.PI - 90})
//         translate(${d.y},0)
//       `,
//       )

//     node
//       .append('circle')
//       .attr('fill', ({ data }) => {
//         return data.is_online ? green : red
//       })
//       .attr('r', dotSize)
//       .style('cursor', 'pointer')
//       .on('mouseover', handleMouseOver)
//       .on('mouseout', handleMouseOut)
//       .on('click', handleMouseClick)

//     node
//       .append('text')
//       .attr('dy', '0.31em')
//       .attr('x', d => (d.x < Math.PI === !d.children ? 6 : -6))
//       .attr('text-anchor', d =>
//         d.x < Math.PI === !d.children ? 'start' : 'end',
//       )
//       .attr('transform', d => (d.x >= Math.PI ? 'rotate(180)' : null))
//       .text(d => d.data.serial_number)
//       .clone(true)
//       .lower()
//       .attr('stroke', 'white')

//     svg.node()

//     svg.attr('viewBox', autoBox)
//   }

//   useEffect(() => {
//     // d3.select(mapRef.current)
//     //   .datum(myData)
//     //   .call(chart)

//     if (mapRef.current && data) {
//       initMindMap(mapRef.current)
//     }
//   }, [])

//   // useEffect(())

//   useEffect(() => {
//     d3.select(mapRef.current).attr('viewBox', autoBox)
//   }, [bounds])
//   return (
//     <svg
//       id="chart"
//       ref={mapRef}
//       style={{ width: '100%', height: 500, cursor: 'auto' }}
//     ></svg>
//   )
// }

// const links = [
//   { source: 'Microsoft', target: 'Amazon', type: 'licensing' },
//   { source: 'Microsoft', target: 'HTC', type: 'licensing' },
//   { source: 'Samsung', target: 'Apple', type: 'suit' },
//   { source: 'Motorola', target: 'Apple', type: 'suit' },
//   { source: 'Nokia', target: 'Apple', type: 'resolved' },
//   { source: 'HTC', target: 'Apple', type: 'suit' },
//   { source: 'Kodak', target: 'Apple', type: 'suit' },
//   { source: 'Microsoft', target: 'Barnes & Noble', type: 'suit' },
//   { source: 'Microsoft', target: 'Foxconn', type: 'suit' },
//   { source: 'Oracle', target: 'Google', type: 'suit' },
//   { source: 'Apple', target: 'HTC', type: 'suit' },
//   { source: 'Microsoft', target: 'Inventec', type: 'suit' },
//   { source: 'Samsung', target: 'Kodak', type: 'resolved' },
//   { source: 'LG', target: 'Kodak', type: 'resolved' },
//   { source: 'RIM', target: 'Kodak', type: 'suit' },
//   { source: 'Sony', target: 'LG', type: 'suit' },
//   { source: 'Kodak', target: 'LG', type: 'resolved' },
//   { source: 'Apple', target: 'Nokia', type: 'resolved' },
//   { source: 'Qualcomm', target: 'Nokia', type: 'resolved' },
//   { source: 'Apple', target: 'Motorola', type: 'suit' },
//   { source: 'Microsoft', target: 'Motorola', type: 'suit' },
//   { source: 'Motorola', target: 'Microsoft', type: 'suit' },
//   { source: 'Huawei', target: 'ZTE', type: 'suit' },
//   { source: 'Ericsson', target: 'ZTE', type: 'suit' },
//   { source: 'Kodak', target: 'Samsung', type: 'resolved' },
//   { source: 'Apple', target: 'Samsung', type: 'suit' },
//   { source: 'Kodak', target: 'RIM', type: 'suit' },
//   { source: 'Nokia', target: 'Qualcomm', type: 'suit' },
// ]

const height = 500

const mapLinks = (data) => {
  const arr = data.parents || data.children || []
  let res = []
  const isParent = Boolean(data.children)
  if (isParent) {
    res = arr.map((el, idx) => ({
      source: 0, // data.serial_number,
      target: idx + 1, // el.serial_number,
    }))
  } else {
    res = arr.map((el, idx) => ({
      source: idx + 1, // data.serial_number,
      target: 0, // el.serial_number,
    }))
  }

  return res
}
const mapNodes = (data) => {
  // const nodesHash = {}
  // links.forEach(function(link) {
  //   link.source =
  //     nodesHash[link.source] || (nodesHash[link.source] = { name: link.source })
  //   link.target =
  //     nodesHash[link.target] || (nodesHash[link.target] = { name: link.target })
  // })
  // const nodes = Object.values(nodesHash)
  // console.log('nodes', nodes)
  // return nodes
  const arr = data.parents || data.children || []

  const nodes = [
    {
      is_online: data.is_online,
      name: data.serial_number,
      device_id: data.device_id,
    },
    ...arr.map((el) => ({ ...el, name: el.serial_number })),
  ]

  return nodes
}

export default function MindMap({ data }) {
  const [wrapperRef, bounds] = useMeasure()
  const mapRef = useRef()
  const linksRef = useRef()
  const nodesRef = useRef()
  const history = useHistory()

  const [links, setLinks] = useState(null)
  const [nodes, setNodes] = useState(null)
  useEffect(() => {
    if (data && !links) setLinks(mapLinks(data))
  }, [data])
  useEffect(() => {
    if (links) setNodes(mapNodes(data))
  }, [links])

  // const nodes = [
  //   { name: 0 },
  //   { name: 1 },
  //   { name: 2 },
  //   { name: 3 },
  //   { name: 4 },
  // ]

  // const links = [
  //   { source: 0, target: 1 },
  //   { source: 0, target: 2 },
  //   { source: 0, target: 3 },
  //   { source: 0, target: 4 },
  // ]

  function autoBox() {
    const { x, y, width } = this.getBBox()
    return [0, 0, bounds.width, height]
    // return [bounds.x, bounds.y, bounds.width, bounds.height]
    // return [0, 0, bounds.width, 500]
  }

  const simulation = useRef()
  function initMindMap(ref) {
    //       .style('font', '10px "Futura New"')

    const dotSize = 6
    function handleMouseOver(d, i) {
      // Add interactivity

      // Use D3 to select element, change color and size
      d3.select(this)
        .attr('r', dotSize * 1.5)
        .attr('cursor', 'pointer')
    }
    function handleMouseOut(d, i) {
      // Use D3 to select element, change color back to normal
      d3.select(this).attr('r', dotSize)
    }
    function handleMouseClick({ device_id }, y) {
      history.push(`/${device_id}`)
    }

    const width = 400
    const svg = d3
      .select(ref)
      .style('max-width', '100%')
      .style('font', '15px "Futura New"')
    // .attr('width', width)
    // .attr('height', height)

    // Per-type markers, as they don't inherit styles.
    svg
      .append('defs')
      .selectAll('marker')
      .data(['suit', 'licensing', 'resolved'])
      .enter()
      .append('marker')
      .attr('id', function (d) {
        return d
      })
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 15)
      .attr('refY', -1.5)
      .attr('markerWidth', 6)
      .attr('markerHeight', 6)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5')
      .attr('fill', '#333')

    function linkArc(d) {
      const dx = d.target.x - d.source.x,
        dy = d.target.y - d.source.y,
        dr = Math.sqrt(dx * dx + dy * dy)
      return (
        'M' +
        d.source.x +
        ',' +
        d.source.y +
        'A' +
        dr +
        ',' +
        dr +
        ' 0 0,1 ' +
        d.target.x +
        ',' +
        d.target.y
      )
    }

    function transform(d) {
      return 'translate(' + d.x + ',' + d.y + ')'
    }

    const path = svg
      .append('g')
      .selectAll('path')
      .data(links)
      .enter()
      .append('path')
      .attr('stroke', '#333')
      .attr('fill', 'none')
      .attr('marker-end', function (d) {
        // return 'url(#' + d.type + ')'
        return `url('#resolved')`
      })

    function dragstarted(d) {
      if (!d3.event.active) simulation.current.alphaTarget(0.3).restart()
      d.fx = d.x
      d.fy = d.y
    }
    function dragged(d) {
      d.fx = d3.event.x
      d.fy = d3.event.y
    }
    function dragended(d) {
      if (!d3.event.active) simulation.current.alphaTarget(0)
      d.fx = null
      d.fy = null
    }
    const circle = svg
      .append('g')
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      .attr('fill', ({ is_online }) => (is_online ? green : red))
      .attr('r', dotSize)
      .on('mouseover', handleMouseOver)
      .on('mouseout', handleMouseOut)
      .on('click', handleMouseClick)
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended),
      )

    const text = svg
      .append('g')
      .selectAll('text')
      .data(nodes)
      .enter()
      .append('text')
      .attr('x', 8)
      .attr('y', '.31em')
      .text(function (d) {
        return d.name
      })

    function updateLinks() {
      path.attr('d', linkArc)
    }

    function updateNodes() {
      circle.attr('transform', transform)
      text.attr('transform', transform)
    }

    function ticked() {
      updateLinks()
      updateNodes()
    }

    simulation.current = d3
      .forceSimulation(nodes)
      .force('center', d3.forceCenter(bounds.width / 2, bounds.height / 2))
      .force('link', d3.forceLink().links(links).distance(60))
      .force('charge', d3.forceManyBody().strength(-300))
      .on('tick', ticked)

    svg.attr('viewBox', autoBox)
  }

  useEffect(() => {
    // d3.select(mapRef.current)
    //   .datum(myData)
    //   .call(chart)

    if (mapRef.current && links && nodes) {
      initMindMap(mapRef.current)
    }
  }, [links, nodes])

  useEffect(() => {
    d3.select(mapRef.current).attr('viewBox', autoBox)
    if (simulation.current) {
      simulation.current.force(
        'center',
        d3.forceCenter(bounds.width / 2, bounds.height / 2),
      )
    }
  }, [bounds])

  return (
    <div ref={wrapperRef}>
      <svg
        id="chart"
        ref={mapRef}
        style={{ width: '100%', height: 500, cursor: 'auto' }}
      ></svg>
    </div>
  )
}
