import React, { Component } from 'react'
import {
  Grid,
  Button,
  Icon,
  Accordion,
  Container,
  Popup,
  Input,
  Form,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { updateStandartSounds } from 'ducks/deviceActions'
import wrapCategory from 'components/categories/wrapCategory'
import { red, lightRed } from 'theme.js'

import { startEmergency } from 'ducks/deviceActions'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${red};
    }

    &.active {
      color: white !important;
      background: ${red};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

const StyledForm = styled(Form)`
  && {
    display: flex;
    width: 80%;
    margin-bottom: 1rem;
    max-width: 600px;

    & > div:first-child {
      margin-right: 0.625rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      & > div {
        width: 100%;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
`

const styles = {
  formWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  formField: { flex: 1 },
}

class Emergency extends Component {
  state = {
    popup: {
      isOpen: false,
    },
    val: 20,
    acceptedFile: [],
    rejectedFile: [],
    cycles: 1,
    unlockedTime: 5,
  }

  onEmergency = e => false

  handleChange = (e, { value }) => this.setState({ selectedSong: value })

  startEmergency = () => {
    const { cycles, unlockedTime, acceptedFile } = this.state

    const file = acceptedFile[0] || null

    this.props.startEmergency({
      id: this.props.id,
      type: 'start_emergency',
      file,
      cycles,
      unlockedTime,
    })
    this.setState({ acceptedFile: [] }) // could potentially clear input even if emergency started unsuccessfully
  }

  handleUpdate = () => {
    const { acceptedFile } = this.state
    if (acceptedFile[0]) {
      this.props.startEmergency({
        id: this.props.id,
        type: 'download_sound',
        file: acceptedFile[0],
      })
      this.setState({ popup: { isOpen: false }, selectedSong: null })
    } else {
      this.setState({ popup: { isOpen: true } })
      setTimeout(() => {
        this.setState({ popup: { isOpen: false } })
      }, 2500)
    }
  }

  onDrop = (accepted, rejected) => {
    this.setState({
      acceptedFile: accepted,
      rejectedFile: rejected,
      fileName: accepted[0].name,
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 2}
          index={2}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Оповещение о ЧС
            <Icon
              name={`angle ${this.props.activeIndex === 2 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 2}>
          <Container>
            {/* <Grid> */}
            <Grid.Row>
              <div style={styles.formWrapper}>
                <StyledForm>
                  <Form.Field style={styles.formField}>
                    <label>Циклов оповещения</label>
                    <Input
                      min={1}
                      size="mini"
                      name="cycles"
                      type="number"
                      value={this.state.cycles}
                      onChange={this.onChange}
                    />
                  </Form.Field>
                  <Form.Field style={styles.formField}>
                    <label>Длительность открытия двери (мин)</label>
                    <Input
                      min={1}
                      size="mini"
                      name="unlockedTime"
                      type="number"
                      value={this.state.unlockedTime}
                      onChange={this.onChange}
                    />
                  </Form.Field>
                </StyledForm>
              </div>

              <Popup
                trigger={
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Dropzone
                      accept=".wav,.mp3"
                      style={{
                        display: 'inline-block',
                        marginRight: '.625rem',
                      }}
                      multiple={false}
                      onDrop={this.onDrop}
                    >
                      {({ getInputProps, getRootProps }) => (
                        <Button
                          circular
                          icon
                          labelPosition="left"
                          style={{
                            width: '220px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <Icon name="file audio outline" />
                          {this.state.fileName
                            ? this.state.fileName
                            : 'Выбрать звуковой файл'}
                        </Button>
                      )}
                    </Dropzone>

                    <Button
                      floated="right"
                      onClick={this.handleUpdate}
                      icon="sync"
                      content="Обновить"
                      style={{ maxWidth: '120px' }}
                      circular
                      disabled={!this.state.fileName}
                    />
                  </div>
                }
                content="Вы не выбрали звук"
                on="click"
                open={this.state.popup.isOpen}
                position="top center"
              />
            </Grid.Row>
            <div
              style={{
                display: 'flex',
                marginTop: '1.625rem',
                justifyContent: 'center',
              }}
            >
              <Button
                circular
                onClick={this.startEmergency}
                color="red"
                icon="play"
                content="Запустить оповещение"
              />
            </div>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

const mapStateToProps = ({ device: { currentDevice } }) => ({
  deviceId: currentDevice.device_id,
})

export default connect(
  mapStateToProps,
  { updateStandartSounds, startEmergency },
)(wrapCategory(Emergency))
