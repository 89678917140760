import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Table,
  Loader,
  Button,
  Modal,
  Header,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  updateFlatInfo,
  clearDeviceStatus,
  lineTest,
  deleteFlat,
  deleteAllFlats,
} from 'ducks/deviceActions'
import Flat from 'components/listings/Flat'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'
import { toastr } from 'react-redux-toastr'
import AddVirtualFlatModal from 'components/modals/AddVirtualFlatModal'
import FlatsGeneral from 'components/forms/FlatsGeneral'

const Wrapper = styled.div`
  i {
    cursor: pointer;
  }
`

// const makeFlatOptions = (firstFlat, lastFlat) => {
//   return Array.from(
//     { length: lastFlat - firstFlat + 1 },
//     (v, k) => k + firstFlat,
//   ).map((flatNum) => ({
//     key: flatNum,
//     text: flatNum,
//     value: flatNum,
//   }))
// }

class FlatsPage extends Component {
  state = {
    addVirtualFlatModal: false,
    confirmModalOpen: false,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  handleModalClose = () => this.setState({ confirmModalOpen: false })

  // componentDidUpdate() {
  //   if (
  //     this.state.flatOptions.length < 1 &&
  //     this.props.firstFlat &&
  //     this.props.lastFlat
  //   ) {
  //     this.setState({
  //       flatOptions: makeFlatOptions(this.props.firstFlat, this.props.lastFlat),
  //     })
  //   }
  // }

  toggleAddVirtualFlatModal = () => {
    this.setState((prev) => ({
      addVirtualFlatModal: !prev.addVirtualFlatModal,
    }))
  }

  render() {
    const {
      match,
      flats,
      updateFlatInfo,
      firstFlat,
      lastFlat,
      lineTest,
    } = this.props

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!flats) {
      return (
        <div style={{ width: '100vw', heigth: '100%' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <Wrapper>
        <Navbar />

        <Container style={{ margin: '4.625rem auto' }}>
          <FlatsGeneral />
          <Button color="pink" onClick={this.toggleAddVirtualFlatModal}>
            Добавить квартиру
          </Button>

          <Modal
            // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
            open={this.state.confirmModalOpen}
            onClose={this.handleModalClose}
            basic
            size="small"
          >
            <Header icon="warning" content="Внимание" />
            <Modal.Content>
              <h3>Вы действительно хотите удалить все квартиры?</h3>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleModalClose} inverted circular>
                Не удалять
              </Button>
              <Button
                onClick={() => {
                  this.props.deleteAllFlats(this.props.match.params.id)
                  this.handleModalClose()
                }}
                color="red"
                circular
              >
                Удалить все квартиры
              </Button>
            </Modal.Actions>
          </Modal>
          {this.props.isIP && (
            <Button onClick={() => this.setState({ confirmModalOpen: true })}>
              Удалить все квартиры
            </Button>
          )}
          <Table selectable color="pink">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">Квартира</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Переадресация SIP
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Адрес переадресации SIP
                </Table.HeaderCell>
                {!this.props.isIP && (
                  <>
                    <Table.HeaderCell textAlign="center">
                      Громкость звонка
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Переадресация аналоговой линии
                    </Table.HeaderCell>
                  </>
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {flats.map((flat, i) => (
                <Flat
                  id={match.params.id}
                  key={flat.num}
                  flat={flat}
                  updateFlatInfo={updateFlatInfo}
                  firstFlat={firstFlat}
                  lastFlat={lastFlat}
                  lineTest={lineTest}
                  isIP={this.props.isIP}
                  handleDeleteFlat={() =>
                    this.props.deleteFlat(this.props.match.params.id, flat.num)
                  }
                />
              ))}
            </Table.Body>
          </Table>
        </Container>
        <AddVirtualFlatModal
          open={this.state.addVirtualFlatModal}
          onClose={this.toggleAddVirtualFlatModal}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  flats:
    state.device.shadowDevice &&
    state.device.shadowDevice.properties &&
    state.device.shadowDevice.properties.flats_config.flats,
  firstFlat: state.device.shadowDevice?.properties?.base?.flats_first,
  lastFlat: state.device.shadowDevice?.properties?.base?.flats_last,
  error: state.deviceActions.error,
  success: state.deviceActions.success,
  isIP: state.device?.shadowDevice?.mode === 'ip',
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  updateFlatInfo,
  clearDeviceStatus,
  lineTest,
  deleteAllFlats,
  deleteFlat,
})(FlatsPage)
