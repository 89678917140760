import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import registerServiceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import 'theme/semantic.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'index.css'

const store = configureStore()
//store.dispatch({ type: 'CLEAR' })

const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Fragment>
        <App />
        <ReduxToastr preventDuplicates position="top-right" progressBar />
      </Fragment>
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

const showUpdateToast = action =>
  toastr.info('Доступна новая версия', 'Нажмите для обновления', {
    onToastrClick: action,
  })
registerServiceWorker(showUpdateToast)
