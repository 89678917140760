import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { List, Icon, Button } from 'semantic-ui-react'
import { formatDeviceId } from 'helpers'

function DeviceListing({
  id,
  isOnline,
  serialNumber,
  motherboardId = '',
  idx,
}) {
  const isFirst = idx === 0
  return (
    <List.Item
      as={Link}
      to={`/${id}`}
      style={{
        padding: '1rem',
        background: isFirst && '#fafafa',
        color: isFirst && 'black',
      }}
    >
      <List.Icon
        name="idea"
        color={isOnline ? 'green' : 'red'}
        // style={{ marginRight: '.625rem' }}
        verticalAlign="middle"
        // size="large"
      />
      <List.Content>
        <List.Header>
          {formatDeviceId(motherboardId.toString())}{' '}
          {/* <span style={{ color: 'black' }}>/</span> {serialNumber} */}
        </List.Header>
        <List.Description style={{ color: '#aeaeae' }}>
          {serialNumber}
        </List.Description>
      </List.Content>
      {/* <List.Content floated="right">
        {isFirst && (
          <Button size="mini" color="black" style={{ fontWeight: '500' }}>
            enter
          </Button>
        )}{' '}
      </List.Content> */}
    </List.Item>
  )
}
//<Link to={`device/${id}`} />

export default DeviceListing
