import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import {
  addKey,
  clearDeviceStatus,
  removeKey,
  downloadKeys,
} from 'ducks/deviceActions'
import Dropzone from 'react-dropzone'
import Key from 'components/listings/Key'
import Navbar from 'components/global/Navbar'

const KEYS_PER_PAGE = 10

class KeysPage extends Component {
  state = {
    newKey: '',
    activePage: 1,
    totalPages: 1,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      nextProps.getDeviceInfoShadow(nextProps.match.params.id)
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
      return { newKey: '', acceptedFile: null }
    }
    if (nextProps.keys) {
      return {
        totalPages: Math.ceil(nextProps.keys.length / KEYS_PER_PAGE),
        currentKeys: nextProps.keys.slice(
          prevState.activePage * KEYS_PER_PAGE - KEYS_PER_PAGE,
          prevState.activePage * KEYS_PER_PAGE,
        ),
      }
    }
    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onAdd = e => {
    const { match } = this.props
    if (this.state.acceptedFile) {
      this.props.addKey({
        uuid: match.params.id,
        file: this.state.acceptedFile[0],
      })
    } else {
      this.props.addKey({
        uuid: match.params.id,
        key: this.state.newKey,
      })
    }
  }

  changePage = (e, { activePage }) => {
    this.setState({
      activePage,
      currentKeys: this.props.keys.slice(
        activePage * KEYS_PER_PAGE - KEYS_PER_PAGE,
        activePage * KEYS_PER_PAGE,
      ),
    })
  }

  downloadKeys = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.props.downloadKeys(id)
  }

  render() {
    const { match, collectingKeys } = this.props
    const { currentKeys } = this.state

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!collectingKeys && !currentKeys) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ minHeight: '100vh', marginTop: '4.625rem' }}>
          <Grid>
            <Grid.Row centered>
              <Grid.Column textAlign="center">
                {!collectingKeys && (
                  <Button
                    content="Скачать .csv с ключами"
                    icon="cloud download"
                    labelPosition="right"
                    onClick={this.downloadKeys}
                    circular
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            {collectingKeys ? (
              <Grid.Row centered>
                <Message negative>
                  <Message.Header>
                    В данный момент включен автосбор. Для добавления и удаления
                    ключей выключите Автосбор
                  </Message.Header>
                </Message>
              </Grid.Row>
            ) : (
              <React.Fragment>
                <Grid.Row centered>
                  <Grid.Column>
                    <Form onSubmit={this.onAdd}>
                      <Form.Field>
                        <Input
                          name="newKey"
                          placeholder="hex"
                          value={this.state.newKey}
                          onChange={this.onChange}
                          width={16}
                          action={
                            <>
                              <Dropzone
                                accept=".csv"
                                multiple={false}
                                onDrop={(accepted, rejected) => {
                                  this.setState({
                                    acceptedFile: accepted,
                                    rejectedFile: rejected,
                                    fileName: accepted[0].name,
                                  })
                                }}
                              >
                                {({ getInputProps, getRootProps }) => (
                                  <Popup
                                    content=".csv файл с ключами"
                                    trigger={
                                      <Button circular {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Icon
                                          name="file outline alternate"
                                          style={{ margin: 0 }}
                                        />
                                        {this.state.fileName}
                                      </Button>
                                    }
                                  ></Popup>
                                )}
                              </Dropzone>
                              <Button type="submit" color="blue">
                                Добавить
                              </Button>
                            </>
                          }
                        />
                      </Form.Field>
                    </Form>

                    <List selection size="big">
                      {currentKeys.length < 1 && (
                        <Message>
                          <Message.Header>Отсутствуют ключи</Message.Header>
                        </Message>
                      )}
                      {currentKeys.map((hex, ind) => (
                        <Key
                          key={ind}
                          hex={hex}
                          uuid={match.params.id}
                          onClick={this.props.removeKey}
                        />
                      ))}
                    </List>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                  <Pagination
                    onPageChange={this.changePage}
                    defaultActivePage={this.state.activePage}
                    totalPages={this.state.totalPages}
                  />
                </Grid.Row>
              </React.Fragment>
            )}
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  keys: device.shadowDevice.properties && device.shadowDevice.properties.keys,
  success: deviceActions.success,
  error: deviceActions.error,
  collectingKeys:
    device.shadowDevice.properties &&
    device.shadowDevice.properties.base.collecting_keys,
})

export default connect(
  mapStateToProps,
  {
    getDeviceInfoShadow,
    addKey,
    clearDeviceStatus,
    removeKey,
    downloadKeys,
  },
)(KeysPage)
