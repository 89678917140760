import React, { Component } from 'react'
import { Form, Button, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { validateToken, clearAuthStatus } from 'ducks/auth'
import { toastr } from 'react-redux-toastr'

class LoginForm extends Component {
  state = {
    jwt: '',
    uuid: ''
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onClick = e => this.props.validateToken(this.state.jwt, this.state.uuid)

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearAuthStatus(), 1000)
    }
    return prevState
  }

  render() {
    return (
      <Form size="huge" onSubmit={this.onClick}>
        <Form.Field>
          {/*<Input*/}
            {/*value={this.state.uuid}*/}
            {/*name="uuid"*/}
            {/*placeholder="id устройства"*/}
            {/*onChange={this.onChange}*/}
            {/*type="text"*/}
            {/*style={{ marginBottom: '40px' }}*/}
          {/*/>*/}
          <Input
            value={this.state.jwt}
            name="jwt"
            placeholder="Введите ваш код"
            onChange={this.onChange}
            type="text"
            action={
              <Button color="red" onClick={this.onClick}>
                Подтвердить
              </Button>
            }
          />
        </Form.Field>
        <Form.Field />
      </Form>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  error: auth.error
})

export default connect(mapStateToProps, { validateToken, clearAuthStatus })(
  LoginForm
)
