export const lightRed = '#eee'

export const red = '#db2828'
export const orange = '#f2711c'
export const yellow = '#fbbd08'
export const olive = '#b5cc18'
export const green = '#21ba45'
export const teal = '#008080'
export const blue = '#2185d0'
export const violet = '#6435c9'
export const purple = '#a333c8'
export const pink = '#e03997'
export const amber = '#FFC107'
