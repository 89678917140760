import React from 'react'
import { connect } from 'react-redux'
import { Icon, Button } from 'semantic-ui-react'

const styles = {
  marginLeft: 'auto',
  marginBottom: '0.625rem',
  background: 'black',
}

const PanelStatus = ({ is_online }) => (
  <Button style={styles} icon labelPosition="left" color="black" circular>
    <Icon
      name="idea"
      style={{ marginRight: '.625rem' }}
      color={is_online ? 'green' : 'red'}
    />
    {is_online ? 'В сети' : 'Вне сети'}
  </Button>
)

const mapStateToProps = ({
  device: {
    currentDevice: { is_online },
  },
}) => ({
  is_online: is_online,
})

export default connect(mapStateToProps)(PanelStatus)
