import React, { Component } from 'react'
import { Container, Loader, Table, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Emergency from 'components/listings/Emergency'
import { getDeviceInfo } from 'ducks/device'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'

const DevicePageStyled = styled.div`
  min-height: 100vh;

  .loading {
    height: 100vh;
    width: 100vw;
  }
`

class EmergencyPage extends Component {
  state = {}

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfo(match.params.id)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.currentDevice &&
      nextProps.currentDevice.properties &&
      nextProps.sounds
    ) {
      const songOptions = nextProps.sounds.map(sound => ({
        text: sound.title,
        key: sound.id,
        value: sound.url
      }))
      return {
        emergency: nextProps.currentDevice.properties.emergency,
        songOptions
      }
    }
    return prevState
  }

  handleChange = (e, { value }, position) =>
    this.setState({ emergency: { ...this.state.emergency, [position]: value } })

  onSubmit = e => false

  render() {
    if (!this.state.emergency || !this.state.songOptions) {
      return (
        <DevicePageStyled>
          <div className="loading">
            <Loader active />
          </div>
        </DevicePageStyled>
      )
    }

    return (
      <div>
        <Navbar />

        <Container style={{ marginTop: '4.625rem' }}>
          <Table selectable color="red">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Мелодия</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {Object.keys(this.state.emergency).map(position => (
                <Emergency
                  key={position}
                  position={position}
                  emergency={this.state.emergency}
                  handleChange={this.handleChange}
                  options={this.state.songOptions}
                />
              ))}
            </Table.Body>

            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  <Button color="red" floated="right" onClick={this.onSubmit}>
                    Обновить данные
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ device }) => ({
  currentDevice: device.currentDevice,
  sounds: device.sounds
})

export default connect(
  mapStateToProps,
  { getDeviceInfo }
)(EmergencyPage)
