import React, { Component } from 'react'
import { Grid, Icon, Accordion, Container } from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'
import { amber } from 'theme.js'
import NetworkSettings from '../forms/NetworkSettings'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${amber};
    }

    &.active {
      color: white !important;
      background: ${amber};
    }
  }
`

class Network extends Component {
  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 14}
          index={14}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Настройка сети
            <Icon
              name={`angle ${this.props.activeIndex === 14 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 14}>
          <Container>
            <Grid>
              <Grid.Row>
                <NetworkSettings />
              </Grid.Row>
            </Grid>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default wrapCategory(Network)
