import React from 'react'
import { Redirect } from 'react-router-dom'
import LoginForm from 'components/forms/LoginForm'
import { Container, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'

const LoginPage = ({ jwt, uuid }) => {
  if (jwt.length > 0) {
    return uuid ? <Redirect to={`/${uuid}`} /> : <Redirect to="/" />
  }
  return (
    <Container>
      <Grid style={{ height: '100vh' }}>
        <Grid.Row>
          <Grid.Column verticalAlign="middle">
            <LoginForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

const mapStateToProps = ({ auth }) => ({
  jwt: auth.jwt,
  uuid: auth.uuid
})

export default connect(mapStateToProps)(LoginPage)
