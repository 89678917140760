import React from 'react'
import { List, Icon } from 'semantic-ui-react'

const ListItemStyle = {
  padding: '1.5rem',
}

const Key = ({ hex, onClick, uuid }) => (
  <List.Item style={ListItemStyle}>
    <List.Content floated="left">
      <Icon name="remove circle" onClick={() => onClick({ uuid, hex })} />
    </List.Content>
    <List.Content floated="left">{hex}</List.Content>
  </List.Item>
)

export default Key
