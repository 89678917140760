import { useCallback, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useMeasure() {
  const [bounds, set] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  })

  const [observer] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        if (entry) {
          set(entry.contentRect)
          // set(entry.target.getBoundingClientRect())
        }
      }),
  )

  const ref = useCallback(
    node => {
      observer.disconnect()
      if (node) {
        observer.observe(node)
      }
    },
    [observer],
  )

  return [ref, bounds]
}
