import React from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import RequireAuth from 'components/global/RequireAuth'
import LoginPage from 'components/pages/LoginPage'
import HomePage from 'components/pages/HomePage'
import DevicePage from 'components/pages/DevicePage'
import FlatsPage from 'components/pages/FlatsPage'
import KeysPage from 'components/pages/KeysPage'
import NotFound from 'components/pages/NotFound'
import EmergencyPage from 'components/pages/EmergencyPage'
import LogsPage from 'components/pages/LogsPage'
import AccessCodesPage from 'components/pages/AccessCodesPage'
import BLEKeysPage from 'components/pages/BLEKeysPage'

const App = () => (
  <Switch>
    <Route path="/login" component={LoginPage} />
    <Route exact path="/" component={RequireAuth(HomePage)} />
    <Route path="/:id/ble" component={RequireAuth(BLEKeysPage)} />
    <Route path="/:id/flats" component={RequireAuth(FlatsPage)} />
    <Route path="/:id/keys" component={RequireAuth(KeysPage)} />
    <Route path="/:id/codes" component={RequireAuth(AccessCodesPage)} />
    <Route path="/:id/emergency" component={RequireAuth(EmergencyPage)} />
    <Route path="/:id/logs" component={RequireAuth(LogsPage)} />
    <Route path="/:id" component={RequireAuth(DevicePage)} />
    <Route component={NotFound} />
  </Switch>
)

export default withRouter(App)
