import { put, takeLatest, call } from 'redux-saga/effects'
import Api from 'api/sputnikApi'
import { toastr } from 'react-redux-toastr'

import { getDeviceInfoShadowWorker } from './device'

export const ADD_BLE_KEY_REQUEST = 'ble/ADD_BLE_KEY_REQUEST'
export const ADD_BLE_KEY_SUCCESS = 'ble/ADD_BLE_KEY_SUCCESS'
export const ADD_BLE_KEY_FAILURE = 'ble/ADD_BLE_KEY_FAILURE'

export const REMOVE_BLE_KEY_REQUEST = 'ble/REMOVE_BLE_KEY_REQUEST'
export const REMOVE_BLE_KEY_SUCCESS = 'ble/REMOVE_BLE_KEY_SUCCESS'
export const REMOVE_BLE_KEY_FAILURE = 'ble/REMOVE_BLE_KEY_FAILURE'

export default function ble(state = {}, { type, payload }) {
  switch (type) {
    default:
      return state
  }
}

export const addBLEKeyRequest = (intercomUuid, key) => ({
  type: ADD_BLE_KEY_REQUEST,
  payload: {
    intercomUuid,
    key,
  },
})
export const addBLEKeySuccess = intercomUuid => ({
  type: ADD_BLE_KEY_SUCCESS,
  payload: intercomUuid,
})
export const addBLEKeyFailure = () => ({
  type: ADD_BLE_KEY_FAILURE,
})

export const removeBLEKeyRequest = (intercomUuid, key) => ({
  type: REMOVE_BLE_KEY_REQUEST,
  payload: {
    intercomUuid,
    key,
  },
})
export const removeBLEKeySuccess = intercomUuid => ({
  type: REMOVE_BLE_KEY_SUCCESS,
  payload: intercomUuid,
})
export const removeBLEKeyFailure = () => ({
  type: REMOVE_BLE_KEY_FAILURE,
})

function* removeBLEKeyWorker({ type, payload }) {
  try {
    yield call(Api.removeBLEKey, payload)
    yield put(removeBLEKeySuccess(payload.intercomUuid))
    toastr.success('Ключ был успешно удален')
  } catch (err) {
    yield put(removeBLEKeyFailure())
    const { message } = err
    toastr.error('При удалении ключа произошла ошибка', message)
  }
}

function* addBLEKeyWorker({ type, payload }) {
  try {
    yield call(Api.addBLEKey, payload)
    yield put(addBLEKeySuccess(payload.intercomUuid))
    toastr.success('Ключ был успешно добавлен')
  } catch (err) {
    yield put(addBLEKeyFailure())
    const { message } = err
    toastr.error('При удалении ключа произошла ошибка', message)
  }
}

export function* bleKeysSagas() {
  yield takeLatest(ADD_BLE_KEY_REQUEST, addBLEKeyWorker)
  yield takeLatest(REMOVE_BLE_KEY_REQUEST, removeBLEKeyWorker)
  yield takeLatest(
    [ADD_BLE_KEY_SUCCESS, REMOVE_BLE_KEY_SUCCESS],
    getDeviceInfoShadowWorker,
  )
}
