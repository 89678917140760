import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  List,
  Container,
  Loader,
  Form,
  Message,
  Pagination,
  Button,
  Icon,
  Input,
  Popup,
} from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'
import { getDeviceInfoShadow, clearCurrentDeviceInfo } from 'ducks/device'
import { addBLEKeyRequest, removeBLEKeyRequest } from 'ducks/ble'
import Key from 'components/listings/Key'
import Navbar from 'components/global/Navbar'
import MaskedInput from 'react-text-mask'

const KEYS_PER_PAGE = 10

const regEx = /^[0-9A-F]+$/i

class KeysPage extends Component {
  state = {
    newKey: '',
    activePage: 1,
    totalPages: 1,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onAdd = e => {
    const { match } = this.props
    console.log(
      /\_+/g.test(this.state.newKey),
      !/\_{8}/g.test(this.state.newKey),
    )
    if (/\_+/g.test(this.state.newKey) && !/\_{8}/g.test(this.state.newKey))
      return toastr.error('Минимальная длина 16 символов')

    const keyNoMask = this.state.newKey.replace(/_/g, '')

    this.props.addBLEKeyRequest(match.params.id, keyNoMask)
  }

  render() {
    const { match, collectingKeys, keys } = this.props

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!keys) {
      return (
        <div style={{ minHeight: '100vw' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <div>
        <Navbar />
        <Container style={{ minHeight: '100vh', marginTop: '4.625rem' }}>
          <Grid>
            <React.Fragment>
              <Grid.Row centered>
                <Grid.Column>
                  <Form onSubmit={this.onAdd}>
                    <Form.Group>
                      <Form.Input
                        name="newKey"
                        placeholder="hex"
                        value={this.state.newKey}
                        onChange={this.onChange}
                        width={16}
                        children={
                          <MaskedInput
                            name="newKey"
                            value={this.state.newKey}
                            onChange={this.onChange}
                            mask={[...Array(16).keys()].map(() => regEx)}
                            placeholder="Hex"
                            required
                          />
                        }
                      />

                      <Form.Button type="submit" color="blue">
                        Добавить
                      </Form.Button>
                    </Form.Group>
                  </Form>

                  <List selection size="big">
                    {keys.length < 1 && (
                      <Message>
                        <Message.Header>Отсутствуют BLE ключи</Message.Header>
                      </Message>
                    )}
                    {keys.map((hex, ind) => (
                      <Key
                        key={ind}
                        hex={hex}
                        uuid={match.params.id}
                        onClick={() =>
                          this.props.removeBLEKeyRequest(match.params.id, hex)
                        }
                      />
                    ))}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = ({ device, deviceActions }) => ({
  keys:
    device.shadowDevice.properties && device.shadowDevice.properties.ble_keys,
})

export default connect(mapStateToProps, {
  getDeviceInfoShadow,
  addBLEKeyRequest,
  removeBLEKeyRequest,
})(KeysPage)
