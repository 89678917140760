import React from 'react'
import { Accordion, Container, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'
import { olive } from 'theme.js'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  a {
    display: block;
  }

  .title {
    &:hover {
      color: white !important;
      background: ${olive};
    }

    &.active {
      color: white !important;
      background: ${olive};
    }
  }
`

const KeysListing = ({ id }) => {
  return (
    <Wrapper>
      <Accordion.Title as={Link} to={`/${id}/codes`}>
        <Container className="category-title">
          Коды доступа
          <Icon name="external" />
        </Container>
      </Accordion.Title>
    </Wrapper>
  )
}

export default wrapCategory(KeysListing)
