import React from 'react'
import { Accordion, Container, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'
import { yellow, lightRed } from 'theme.js'
import { Link } from 'react-router-dom'

const Wrapper = styled.div`
  a {
    display: block;
  }

  .title {
    &:hover {
      color: white !important;
      background: ${yellow};
    }

    &.active {
      color: white !important;
      background: ${yellow};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

const LogsListing = ({ id }) => {
  return (
    <Wrapper>
      <Accordion.Title as={Link} to={`/${id}/logs`}>
        <Container className="category-title">
          логи
          <Icon name="external" />
        </Container>
      </Accordion.Title>
    </Wrapper>
  )
}

export default wrapCategory(LogsListing)
