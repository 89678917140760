import React, { Component } from 'react'
import {
  Button,
  Table,
  Icon,
  Dropdown,
  Input,
  Popup,
  Form,
  Modal,
  Header,
} from 'semantic-ui-react'
import { RIEInput } from 'riek'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'theme/slider.css'
import { pink } from 'theme'
import { ReactComponent as Phone } from 'assets/phone.svg'
import { ReactComponent as PhoneBlocked } from 'assets/phone_locked.svg'

const SliderWithTooltip = createSliderWithTooltip(Slider)

const sipOptions = [
  // { key: '1', text: 'после звонка', value: 'after_call' },
  { key: '1', text: 'да', value: 'direct' },
  { key: '2', text: 'нет', value: 'none' },
]

// const flatOptions = [...Array(10).keys()].map((i) => ({
//   key: i,
//   text: i,
//   value: i,
// }))

class Flat extends Component {
  state = {
    blocked: this.props.flat.blocked,
    redirection: this.props.flat.redirection,
    sip_contact: this.props.flat.sip_contact,
    volume: Number(this.props.flat.sound_vol),
    modified: false,
    alias: this.props.flat?.analog_settings?.alias,
    confirmModalOpen: false,
    analogLineBlock: this.props.flat?.analog_settings?.blocked || false,
  }

  onBlockToggle = (e) =>
    this.setState({ blocked: !this.state.blocked, modified: true })

  onAnalogBlockToggle = (e) =>
    this.setState({
      analogLineBlock: !this.state.analogLineBlock,
      modified: true,
    })

  handleChange = (e, { value }) =>
    this.setState({ redirection: value, modified: true })

  onSIPChange = (e) => {
    this.setState(e)
    this.setState({ modified: true })
  }

  updateFlatInfo = () => {
    this.props.updateFlatInfo({
      uuid: this.props.id,
      num: Number.parseInt(this.props.flat.num, 10),
      blocked: this.state.blocked,
      redirection: this.state.redirection,
      sip_contact: this.state.sip_contact,
      sound_vol: this.state.volume,
      analog_settings: {
        alias: Number(this.state.alias),
        blocked: this.state.analogLineBlock,
      },
    })
    this.setState({ modified: false })
  }

  onConfirm = () => {
    const { alias, modified } = this.state
    const { firstFlat, lastFlat } = this.props

    if (modified) {
      this.updateFlatInfo()
    }
  }

  onVolumeChange = (volume) =>
    this.setState({
      volume,
      modified: true,
    })

  handleModalClose = () => this.setState({ confirmModalOpen: false })

  render() {
    const {
      blocked,
      redirection,
      sip_contact,
      modified,
      volume,
      analog_settings,
    } = this.state

    const isVirtual =
      Number(this.props.flat.num) < this.props.firstFlat ||
      Number(this.props.flat.num) > this.props.lastFlat

    return (
      <Table.Row warning={modified}>
        <Modal
          // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
          open={this.state.confirmModalOpen}
          onClose={this.handleModalClose}
          basic
          size="small">
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>Вы действительно хотите удалить квартиру?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleModalClose} inverted circular>
              Не удалять
            </Button>
            <Button
              onClick={() => {
                this.props.handleDeleteFlat()
                this.handleModalClose()
              }}
              color="red"
              circular>
              Удалить квартиру
            </Button>
          </Modal.Actions>
        </Modal>

        <Table.Cell collapsing textAlign="center" width={1}>
          <Popup
            trigger={
              <Icon
                name={blocked ? 'alarm mute' : 'alarm'}
                onClick={this.onBlockToggle}
                color="pink"
                style={{ margin: 0 }}
              />
            }
            content="Отключение вызова в трубку и SIP"
            position="center"
          />

          {!this.props.isIP && (
            <>
              <Popup
                trigger={
                  <Icon
                    name="bug"
                    // onClick={this.onBlockToggle}
                    onClick={() =>
                      this.props.lineTest({
                        deviceUuid: this.props.id,
                        flat: this.props.flat.num,
                      })
                    }
                    color="pink"
                    style={{ marginLeft: 8 }}
                  />
                }
                content="Диагностика линии"
                position="center"
              />
              <Popup
                trigger={
                  <i
                    onClick={this.onAnalogBlockToggle}
                    style={{ marginLeft: 8, height: 14, position: 'relative' }}
                    className={`${
                      this.state.analogLineBlock ? 'grey' : 'pink'
                    } icon`}>
                    {this.state.analogLineBlock ? (
                      <PhoneBlocked style={{ position: 'absolute', left: 0 }} />
                    ) : (
                      <Phone style={{ position: 'absolute', left: 0 }}></Phone>
                    )}
                  </i>
                }
                content={
                  this.state.analogLineBlock
                    ? 'Разблокировать аналоговую линию'
                    : 'Заблокировать аналоговую линию'
                }
                position="center"
              />
            </>
          )}

          <>
            <Icon
              name="trash"
              // onClick={this.onBlockToggle}
              onClick={() => this.setState({ confirmModalOpen: true })}
              color="pink"
              style={{ marginLeft: 8 }}
            />
          </>
        </Table.Cell>
        <Table.Cell width={1} textAlign="center">
          №<strong>{this.props.flat.num}</strong>
        </Table.Cell>
        <Table.Cell width={2}>
          <Dropdown
            fluid
            onChange={this.handleChange}
            options={sipOptions}
            placeholder="Переадресация"
            selection
            value={redirection}
            size="mini"
          />
        </Table.Cell>
        <Table.Cell width={3} textAlign="center">
          <RIEInput
            value={sip_contact || 'нет'}
            change={this.onSIPChange}
            propName="sip_contact"
          />
        </Table.Cell>

        {!this.props.isIP && (
          <>
            <Table.Cell width={4}>
              <SliderWithTooltip
                trackStyle={{ backgroundColor: pink }}
                value={volume}
                min={0}
                max={100}
                step={1}
                onChange={this.onVolumeChange}
              />
            </Table.Cell>
            <Table.Cell width={3}>
              <Input
                label="На линию"
                onChange={(e, { value }) =>
                  this.setState({ alias: value, modified: true })
                }
                placeholder={`Номер квартиры`}
                style={{ maxWidth: 80 }}
                type="number"
                size="mini"
                value={this.state.alias}
              />
            </Table.Cell>
          </>
        )}

        <Table.Cell width={3}>
          {modified && (
            <Button
              circular
              style={{ paddingLeft: '.625rem', paddingRight: '.625rem' }}
              floated="right"
              color="yellow"
              onClick={this.onConfirm}>
              Применить
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default Flat
