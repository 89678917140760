import React, { Component, Fragment } from 'react'
import {
  Dropdown,
  Grid,
  Container,
  Loader,
  Table,
  Button,
  Message,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import LogEntry from 'components/listings/LogEntry'
import {
  getDeviceLogs,
  getDeviceInfoShadow,
  clearCurrentDeviceInfo,
} from 'ducks/device'
import {
  clearDeviceStatus,
  getNetInfo,
  getURLInfo,
  getDeviceKeysCount,
} from 'ducks/deviceActions'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'
import GoToTop from 'components/global/GoToTop'

const DevicePageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const options = [
  { key: '1', text: '10', value: 10 },
  { key: '2', text: '25', value: 25 },
  { key: '3', text: '50', value: 50 },
  { key: '4', text: '75', value: 75 },
]

class LogsPage extends Component {
  state = {
    logsQty: options[0].value,
    showButtons: false,
    error: false,
    activePage: 1,
  }

  componentDidMount() {
    const { match } = this.props
    this.props.getDeviceInfoShadow(match.params.id)
    this.props.getDeviceLogs(
      match.params.id,
      this.state.logsQty,
      this.state.activePage,
    )
    window.addEventListener('keydown', this.showButtons)
    // this._updateIntervalId = window.setInterval(this.updateDeviceInfo, 1000)
  }

  componentWillUnmount() {
    this.props.dispatch(clearDeviceStatus())
    clearInterval(this._updateIntervalId)
    this.props.dispatch(clearCurrentDeviceInfo())
    window.removeEventListener('keydown', this.showButtons)
  }

  updateDeviceInfo = async () => {
    const { match } = this.props
    if (this.state.activePage === 1) {
      this.props.getDeviceLogs(
        match.params.id,
        this.state.logsQty,
        this.state.activePage,
      )
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error) {
      props.dispatch(clearDeviceStatus())
      return { error: true }
    }
    return state
  }

  onChange = (e, { value }) =>
    this.setState({
      logsQty: value,
    })

  showButtons = e => {
    if (e.keyCode === 16) {
      this.setState({
        showButtons: !this.state.showButtons,
      })
      console.log(this.state.showButtons)
      console.log(e.keyCode)
    }
  }

  onClick = e =>
    this.props.getDeviceLogs(
      this.props.match.params.id,
      this.state.logsQty,
      this.state.activePage,
    )

  nextPage = e => {
    this.props.getDeviceLogs(
      this.props.match.params.id,
      this.state.logsQty,
      this.state.activePage + 1,
    )
    this.setState({ activePage: this.state.activePage + 1 })
  }

  prevPage = e => {
    if (this.state.activePage < 1) {
      return
    }
    this.props.getDeviceLogs(
      this.props.match.params.id,
      this.state.logsQty,
      this.state.activePage - 1,
    )
    this.setState({ activePage: this.state.activePage - 1 })
  }

  getNetInfo = () => {
    this.props.getNetInfo({
      deviceUuid: this.props.match.params.id,
      logsQty: this.state.logsQty,
      activePage: this.state.activePage,
    })
  }

  getURLInfo = () => {
    this.props.getURLInfo({
      deviceUuid: this.props.match.params.id,
      logsQty: this.state.logsQty,
      activePage: this.state.activePage,
    })
  }

  getDeviceKeysCount = () => {
    this.props.getDeviceKeysCount({
      deviceUuid: this.props.match.params.id,
      logsQty: this.state.logsQty,
      activePage: this.state.activePage,
    })
  }

  render() {
    const { logs } = this.props

    if (this.state.error)
      return (
        <Fragment>
          <Navbar />
          <DevicePageStyled>
            <Message error size="large">
              Произошла ошибка при загрузке логов панели. Убедитесь, что панель
              зарегистрирована.
            </Message>
          </DevicePageStyled>
        </Fragment>
      )
    if (!logs.collection || logs.collection.length < 1) {
      return (
        <DevicePageStyled>
          <div className="loading">
            <Loader active />
          </div>
        </DevicePageStyled>
      )
    }

    return (
      <div tabIndex="0">
        <Navbar />
        <GoToTop />

        <Container style={{ marginTop: '4.625rem' }}>
          <Grid>
            <Grid.Row>
              <Grid.Column
                style={{ padding: 0 }}
                textAlign="right"
                floated="right"
              >
                {this.state.showButtons && (
                  <Fragment>
                    <Button
                      content="Запросить сетевые настройки"
                      color="grey"
                      floated="left"
                      onClick={this.getNetInfo}
                      circular
                    />
                    <Button
                      content="Запросить настройки URL"
                      color="grey"
                      floated="left"
                      onClick={this.getURLInfo}
                      circular
                    />
                    <Button
                      content="Запросить количество ключей на панели"
                      color="grey"
                      floated="left"
                      onClick={this.getDeviceKeysCount}
                      circular
                    />
                  </Fragment>
                )}
                <Dropdown
                  placeholder="Количество логов"
                  selection
                  onChange={this.onChange}
                  value={this.state.logsQty}
                  options={options}
                  floated="right"
                  style={{ marginRight: '.625rem' }}
                />
                <Button
                  color="yellow"
                  floated="right"
                  onClick={this.onClick}
                  circular
                >
                  Обновить данные
                </Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {logs.isFetching ? (
                <div style={{ height: 400 }}>
                  <Loader active />
                </div>
              ) : (
                <Table selectable color="yellow">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        Дата
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Модуль
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Действие
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Детали
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {logs.collection.map((entry, i) => (
                      <LogEntry entry={entry} key={i} />
                    ))}
                  </Table.Body>

                  <Container
                    style={{
                      position: 'fixed',
                      bottom: 20,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        background: 'transparent',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Table.Row>
                        <Table.HeaderCell colSpan="4">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              color="yellow"
                              circular
                              labelPosition="left"
                              icon="left chevron"
                              content="Пред."
                              disabled={this.state.activePage === 1}
                              onClick={this.prevPage}
                            />
                            <Button
                              color="yellow"
                              circular
                              labelPosition="right"
                              icon="right chevron"
                              content="След."
                              onClick={this.nextPage}
                            />
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </div>
                    {/* <Table.Footer fullWidth> */}

                    {/* </Table.Footer> */}
                  </Container>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  logs: state.device.logs,
  error: state.device.error,
})

export default connect(mapStateToProps, {
  getDeviceLogs,
  getDeviceInfoShadow,
  getNetInfo,
  getURLInfo,
  getDeviceKeysCount,
})(LogsPage)
