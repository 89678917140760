import { createStore, applyMiddleware } from 'redux'
// import throttle from 'lodash/throttle'
import rootReducer from 'ducks/index'
import { loadState } from './localStorage'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from 'ducks/index'

const sagaMiddleware = createSagaMiddleware()

const configureStore = () => {
  const persistedState = loadState()
  const middleware = [sagaMiddleware]
  const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
