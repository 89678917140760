import React from 'react'
import { Accordion, Container, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'
import { lightRed } from 'theme.js'
import Flats from 'components/forms/FlatsGeneral'
import Switches from 'components/forms/Switches'
import SoundSettings from 'components/forms/SoundSettings'
import DoorTimer from 'components/forms/DoorTimer'
import ResistanceLevels from 'components/forms/ResistanceLevels'
import TimeoutSettings from 'components/forms/TimeoutSettings'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${'#000'};
    }

    &.active {
      color: white !important;
      background: ${'#000'};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

const Flex = styled.div`
  display: grid;
  /* grid-auto-rows: 1fr; */
  grid-gap: 1rem;
  & > div {
    margin: 0rem !important;
  }

  grid-template-columns: repeat(5, minmax(min-content, 1fr));
  grid-template-areas:
    'controls timeout slider-1 slider-1 slider-1'
    'flats door slider-2 slider-2 slider-2';
  grid-template-rows: max-content;

  @media screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'controls'
      'flats'
      'door'
      'timeout'
      'slider-1'
      'slider-2';

    /* grid-template-columns: repeat(12, minmax(auto-fit,1fr)); */
  }
`

const GeneralSettings = ({ activeIndex, handleClick }) => (
  <Wrapper>
    <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
      <Container className="category-title">
        общие настройки
        <Icon name={`angle ${activeIndex === 0 ? 'down' : 'right'}`} />
      </Container>
    </Accordion.Title>

    <Accordion.Content active={activeIndex === 0}>
      <Container>
        <Flex>
          <Switches style={{ gridArea: 'controls' }} />
          <Flats style={{ gridArea: 'flats' }} />

          <DoorTimer style={{ gridArea: 'door' }} />

          <SoundSettings style={{ gridArea: 'slider-1' }} />
          <ResistanceLevels style={{ gridArea: 'slider-2' }} />

          <TimeoutSettings style={{ gridArea: 'timeout' }}></TimeoutSettings>
          {/* <Switches
            style={{ gridArea: 'controls', gridArea: '1 / 1 / 1 / span 4' }}
          />
          <Flats
            style={{ gridArea: 'controls', gridArea: '1 / 5 / 1 / span 4' }}
          />

          <DoorTimer
            style={{ gridArea: 'controls', gridArea: '1 / 9 / 1 / span 4' }}
          />

          <SoundSettings
            style={{ gridArea: 'slider-1', gridArea: '2 /1 / 2 / span 6' }}
          />
          <ResistanceLevels
            style={{ gridArea: 'slider-2', gridArea: '2 / 7 / 2 / span 6' }}
          /> */}
        </Flex>
      </Container>
    </Accordion.Content>
  </Wrapper>
)

export default wrapCategory(GeneralSettings)
