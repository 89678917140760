import React from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  height: 100%;
  width: 80px;
  left: 0;
  top: 0;
  background: #fbbd0823;
  z-index: 100;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  visibility: ${() => console.log(window.scrollY)};

  &:hover {
    background: #fbbd0850;
  }
`

const GoToTop = () => (
  <Wrapper onClick={() => window.scrollTo(0, 0)}>
    <Icon name="arrow up" />
  </Wrapper>
)

export default GoToTop
