import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Label, Grid, Form, Segment, Button, Header } from 'semantic-ui-react'
// import Slider from 'components/global/Slider'
import { updateSoundConfig } from 'ducks/deviceActions'
import Slider, { createSliderWithTooltip, Range } from 'rc-slider'
import 'theme/slider.css'
import { blue } from 'theme'

const SliderWithTooltip = createSliderWithTooltip(Slider)
const RangeWithTooltip = createSliderWithTooltip(Range)

const center = {
  display: 'flex',
  justifyContent: 'center',
}

class SoundSettings extends Component {
  state = {
    mainVolume: parseInt(this.props.sound_config.sound_config.general, 10),
    micLevel: parseInt(
      this.props.sound_config.sound_config.speak_handset_tx,
      10,
    ),
    intercomSpeakerVolume: parseInt(
      this.props.sound_config.sound_config.speak_loudspeaker,
      10,
    ),
    sipVolume: parseInt(this.props.sound_config.sound_config.speak_sip, 10),
    modified: false,
  }

  onSubmit = e => {
    this.props.updateSoundConfig({
      uuid: this.props.id,
      general: this.state.mainVolume,
      speak_handset_tx: this.state.micLevel,
      speak_loudspeaker: this.state.intercomSpeakerVolume,
      speak_sip: this.state.sipVolume,
    })
    this.setState({
      modified: false,
    })
  }

  // static getDerivedStateFromProps(props,state) {
  //   if ()
  // }

  onChange = prop => val => this.setState({ [prop]: val, modified: true })

  render() {
    const {
      mainVolume,
      micLevel,
      intercomSpeakerVolume,
      sipVolume,
      modified,
    } = this.state
    return (
      <Segment style={{ display: 'flex' }} {...this.props}>
        <Header>Настройки звука</Header>
        <div style={{ ...center, flexDirection: 'column', flex: 1 }}>
          <Form>
            <Form.Field style={{ marginBottom: '3%' }}>
              <label style={{ display: 'inline' }}>
                Громкость основной работы {mainVolume}
              </label>
              <div>
                <SliderWithTooltip
                  trackStyle={{ backgroundColor: blue }}
                  value={mainVolume}
                  min={0}
                  max={100}
                  step={1}
                  onChange={this.onChange('mainVolume')}
                />
              </div>
            </Form.Field>

            <Form.Field style={{ marginBottom: '3%' }}>
              <label style={{ display: 'inline' }}>
                Чувствительность микрофона {micLevel}
              </label>
              <SliderWithTooltip
                trackStyle={{ backgroundColor: blue }}
                value={micLevel}
                min={0}
                max={100}
                step={1}
                onChange={this.onChange('micLevel')}
              />
            </Form.Field>

            <Form.Field style={{ marginBottom: '3%' }}>
              <label style={{ display: 'inline' }}>
                Громкость динамика панели {intercomSpeakerVolume}
              </label>
              <SliderWithTooltip
                trackStyle={{ backgroundColor: blue }}
                value={intercomSpeakerVolume}
                min={0}
                max={100}
                step={1}
                onChange={this.onChange('intercomSpeakerVolume')}
              />
            </Form.Field>

            <Form.Field style={{ marginBottom: '3%' }}>
              <label style={{ display: 'inline' }}>
                Громкость SIP {sipVolume}
              </label>
              <SliderWithTooltip
                trackStyle={{ backgroundColor: blue }}
                value={sipVolume}
                min={0}
                max={100}
                step={1}
                onChange={this.onChange('sipVolume')}
              />
            </Form.Field>
            <Form.Button
              circular
              color="black"
              onClick={this.onSubmit}
              className="update-button"
              disabled={!modified}
            >
              Обновить
            </Form.Button>
          </Form>
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = ({ device }) => ({
  data: device.shadowDevice.properties && device.shadowDevice.properties.base,
  sound_config:
    device.shadowDevice.properties &&
    device.shadowDevice.properties.sound_config,
  id: device.shadowDevice && device.shadowDevice.device_id,
})

export default connect(mapStateToProps, { updateSoundConfig })(SoundSettings)
