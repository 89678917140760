export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('devicesAppState')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveToken = ({ jwt, uuid }) => {
  try {
    const serializedState = JSON.stringify({
      auth: { jwt, isLoggedIn: true, uuid }
    })
    localStorage.setItem('devicesAppState', serializedState)
  } catch (err) {
    console.log(err)
  }
}
