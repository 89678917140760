import React from 'react'
import styled from 'styled-components'

export default function wrapCategory(WrappedComponent) {
  const StyledWrapper = styled.div`
    cursor: pointer;
    background: white;
    /* border-bottom: #eee 2px solid; */

    .ui.container.category-title {
      display: flex;
      margin-left: 0.325rem;
    }

    .title {
      text-transform: capitalize;
      /* color: #fff !important; */
      padding: 2.625rem 0 !important;
      font-size: 1.325rem !important;

      i {
        margin-left: auto;
        /* visibility: hidden; */
      }

      &:hover,
      &.active {
        i {
          visibility: visible;
        }
      }
    }

    .content {
      font-size: 1.5rem;
      padding: 3.625rem 0 !important;

      &.active {
        background: #fbfbfb;
      }
    }

    .update-button {
      margin-top: auto !important;
    }
  `

  const Category = props => (
    <StyledWrapper>
      <WrappedComponent {...props} />
    </StyledWrapper>
  )

  return Category
}
