import { put, takeLatest, call, select, delay } from 'redux-saga/effects'
import Api from 'api/sputnikApi'

// types
const GET_ALL_DEVICES_REQUEST = 'devices/GET_ALL_DEVICES_REQUEST'
const GET_ALL_DEVICES_SUCCESS = 'devices/GET_ALL_DEVICES_SUCCESS'
const GET_ALL_DEVICES_FAILURE = 'devices/GET_ALL_DEVICES_FAILURE'

const initialState = {
  devices: [],
  fetched: false,
}

// reducer
export default function devices(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_DEVICES_REQUEST:
      return { ...state, fetching: true }
    case GET_ALL_DEVICES_SUCCESS:
      return {
        ...state,
        devices: payload.devices,
        totalPages: payload.totalPages,
        error: '',
        fetched: true,
        fetching: false,
      }
    case GET_ALL_DEVICES_FAILURE:
      return { ...state, error: payload, fetched: true, fetching: false }
    case 'CLEAR':
      return initialState
    default:
      return state
  }
}

// action creators
export const getAllDevices = (page = 1, query = null) => ({
  type: GET_ALL_DEVICES_REQUEST,
  payload: { page, query },
})

// sagas
const isFetched = (state) => state.devices.fetched

function* getAllDevicesWorker({ type, payload }) {
  try {
    // const fetched = yield select(isFetched)
    // if (!fetched) {
    //   yield delay(1000)
    // }

    const response = yield call(Api.getAllDevices, payload)
    const devices = response.data.intercoms
    const totalPages = response.data.total_pages
    yield put({
      type: GET_ALL_DEVICES_SUCCESS,
      payload: { devices, totalPages },
    })
  } catch (err) {
    yield put({ type: GET_ALL_DEVICES_FAILURE, payload: err.message })
  }
}

export function* devicesSaga() {
  yield takeLatest(GET_ALL_DEVICES_REQUEST, getAllDevicesWorker)
}
