import React from 'react'
import { List, Icon } from 'semantic-ui-react'

const ListItemStyle = {
  padding: '1.5rem',
}

const Key = ({ value, onClick, exp_time }) => (
  <List.Item style={ListItemStyle}>
    <List.Content floated="left">
      <Icon name="remove circle" onClick={onClick} />
    </List.Content>
    <List.Content floated="left">
      {value.length == 3 ? `0${value}` : value}
    </List.Content>
    <List.Content floated="right">
      действует{' '}
      {exp_time ? ` до ${new Date(exp_time).toLocaleString()}` : ' бессрочно'}
    </List.Content>
  </List.Item>
)

export default Key
