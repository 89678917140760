import React, { useRef, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import * as hljs from 'highlight.js/lib'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/github-gist.css'

hljs.registerLanguage('json', json)

const modules = {
  log: 'Сервер',
  intercom: 'Домофон',
  api: 'API',
}

const actions = {
  open_door: 'Команда открыть дверь',
  poll: 'Социальный опрос',
  emergency: 'Чрезвычайное происшествиe',
  online: 'Присоединение к серверу',
  offline: 'Отсоединение от сервера',
  intercom_log: 'Внутренняя операция',
  update_config: 'Обновление конфигурации',
  update_flats: 'Обновление квартир',
  reset: 'Перезагузка',
  update_user_sounds: 'Обновление звуков',
  auth_num: 'Открытие двери через код',
}

const LogEntry = ({ entry }) => {
  const date = new Date(entry.date_time).toLocaleString('ru-RU')

  const codeRef = useRef()

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightBlock(codeRef.current)
    }
  }, [])

  switch (entry.module) {
    case 'api':
      return (
        <Table.Row>
          <Table.Cell width={2} textAlign="center">
            <strong>{date}</strong>
          </Table.Cell>
          <Table.Cell width={1} textAlign="center">
            {entry.module}
          </Table.Cell>
          <Table.Cell width={2} textAlign="center">
            {entry.data ? actions[entry.data.action] : 'None'}
          </Table.Cell>
          <Table.Cell width={5} textAlign="left">
            <pre>
              <code ref={codeRef} style={{ background: 'transparent' }}>
                {JSON.stringify(entry, null, 2)}
              </code>
            </pre>
          </Table.Cell>
        </Table.Row>
      )
    default:
      return (
        <Table.Row>
          <Table.Cell width={2} textAlign="center">
            <strong>{date}</strong>
          </Table.Cell>
          <Table.Cell width={1} textAlign="center">
            {modules[entry.module]}
          </Table.Cell>
          <Table.Cell width={2} textAlign="center">
            {entry.data ? actions[entry.data.action] : 'None'}
          </Table.Cell>
          <Table.Cell width={5} textAlign="left">
            <pre>
              <code
                className="json"
                ref={codeRef}
                style={{ background: 'transparent' }}
              >
                {JSON.stringify(entry, null, 2)}
              </code>
            </pre>
          </Table.Cell>
        </Table.Row>
      )
  }
}

export default LogEntry
