import React from 'react'
import { Table, Dropdown } from 'semantic-ui-react'

const Emergency = ({ handleChange, emergency, position, options }) => (
  <Table.Row>
    <Table.Cell width={1} textAlign="center">
      <strong>{position}</strong>
    </Table.Cell>
    <Table.Cell width={15}>
      <Dropdown
        search
        fluid
        placeholder="Мелодия"
        selection
        onChange={(e, props) => handleChange(e, props, position)}
        value={emergency[position]}
        options={options}
      />
    </Table.Cell>
  </Table.Row>
)

export default Emergency
