import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const NotFound = () => (
  <Container>
    <Grid style={{ height: '100vh' }}>
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          Страница не найдена.
          <Link to="/">Вернуться на главную</Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
)

export default NotFound
