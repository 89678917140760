import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Label,
  Grid,
  Form,
  Input,
  Segment,
  Button,
  Header,
} from 'semantic-ui-react'
import { updateDoorOpenTimers } from 'ducks/deviceActions'

const center = {
  display: 'flex',
  justifyContent: 'center',
}

class DoorTimer extends Component {
  state = {
    general: this.props.data.door_open_time_general,
    social_message: this.props.data.door_open_time_social,
    emergency_message: this.props.data.door_open_time_emergency,
    bluetooth: this.props.data.door_open_time_bt,
    modified: false,
  }

  onSubmit = e => {
    debugger
    this.props.updateDoorOpenTimers({
      id: this.props.id,
      door_open_time_general: this.state.general,
      door_open_time_social: this.state.social_message,
      door_open_time_emergency: this.state.emergency_message,
      door_open_time_bt: this.state.bluetooth,
    })
    this.setState({
      modified: false,
    })
  }

  onChange = e =>
    this.setState({ [e.target.name]: e.target.value, modified: true })

  render() {
    const { general, social_message, emergency_message, bluetooth } = this.state
    return (
      <Segment {...this.props}>
        <Header>Время открытия двери</Header>
        <div style={{ ...center, flexDirection: 'column', flex: 1 }}>
          <Form>
            <Form.Field>
              <label>Общее</label>
              <Input
                placeholder="Общее"
                size="mini"
                name="general"
                type="number"
                value={general}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При социальном сообщении</label>
              <Input
                placeholder="При социальном сообщении"
                size="mini"
                name="social_message"
                type="number"
                value={social_message}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При экстренном сообщении</label>
              <Input
                placeholder="При экстренном сообщении"
                size="mini"
                name="emergency_message"
                type="number"
                value={emergency_message}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При bluetooth подключении</label>
              <Input
                placeholder="При bluetooth подключении"
                size="mini"
                name="bluetooth"
                type="number"
                value={bluetooth}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Button
              circular
              style={{
                margin: '1rem auto',
                marginBottom: 0,
                marginTop: 'auto',
              }}
              color="black"
              onClick={this.onSubmit}
              className="update-button"
              disabled={!this.state.modified}
            >
              Обновить
            </Form.Button>
          </Form>
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = ({ device }) => ({
  data: device.shadowDevice.properties && device.shadowDevice.properties.base,
  id: device.shadowDevice.device_id,
})

export default connect(mapStateToProps, { updateDoorOpenTimers })(DoorTimer)
