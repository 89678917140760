import React, { Component } from 'react'
import {
  Accordion,
  Button,
  Container,
  Icon,
  Table,
  Form,
} from 'semantic-ui-react'
import styled from 'styled-components'
import Flatpickr from 'react-flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import wrapCategory from 'components/categories/wrapCategory'
import Dropzone from 'react-dropzone'
import { orange, lightRed } from 'theme.js'
import UploadSoundForm from '../forms/UploadSoundForm'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${orange};
    }

    &.active {
      color: white !important;
      background: ${orange};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }

  .m-calendar {
    table {
      tr {
        td:first-child {
          border-left: 1px solid #dfe0e4 !important;
        }
      }
      tr:first-child {
        td {
          border-top: 1px solid #dfe0e4 !important;
        }
      }

      td{
        text-align: center;
        padding 8px 0;
      }
    }
  }
`

class Poll extends Component {
  handleSubmit = (start, end, acceptedFile) => {
    this.props.onUpdateSound(
      'polling',
      start.toISOString(),
      end.toISOString(),
      acceptedFile[0],
    )
  }

  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 5}
          index={5}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Опросы
            <Icon
              name={`angle ${this.props.activeIndex === 5 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 5}>
          <Container style={{ fontSize: '1rem' }}>
            <UploadSoundForm
              data={this.props.data}
              color="orange"
              onSubmit={this.handleSubmit}
            />
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default wrapCategory(Poll)
