import { put, takeLatest, all, fork, call } from 'redux-saga/effects'
import Api from 'api/sputnikApi'
import { saveToken } from 'store/localStorage'

// types
const VALIDATE_TOKEN_REQUEST = 'auth/VALIDATE_TOKEN_REQUEST'
const VALIDATE_TOKEN_SUCCESS = 'auth/VALIDATE_TOKEN_SUCCESS'
const VALIDATE_TOKEN_FAILURE = 'auth/VALIDATE_TOKEN_FAILURE'

const LOG_OUT = 'LOG_OUT'

const CLEAR_AUTH_STATUS = 'CLEAR_AUTH_STATUS'

const initialState = {
  jwt: '',
  isLoggedIn: false,
}

// reducer
export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        jwt: payload.jwt,
        uuid: payload.uuid,
        error: '',
        isLoggedIn: true,
      }
    case VALIDATE_TOKEN_FAILURE:
      return { ...state, error: payload }
    case LOG_OUT:
      localStorage.clear()
      return initialState
    case CLEAR_AUTH_STATUS:
      return { ...state, error: '' }
    default:
      return state
  }
}

// action creators
export const validateToken = (jwt, uuid) => ({
  type: VALIDATE_TOKEN_REQUEST,
  payload: {
    jwt,
    uuid,
  },
})

export const logOut = payload => ({
  type: LOG_OUT,
})

export const clearAuthStatus = () => ({
  type: CLEAR_AUTH_STATUS,
})

// sagas
function* validateTokenWorker({ type, payload }) {
  try {
    yield call(Api.validateToken, payload)
    saveToken(payload)
    yield put({ type: VALIDATE_TOKEN_SUCCESS, payload })
  } catch (err) {
    yield put({
      type: VALIDATE_TOKEN_FAILURE,
      payload: 'Ваш код не прошел проверку',
    })
  }
}

function* validateTokenWatcher() {
  yield takeLatest(VALIDATE_TOKEN_REQUEST, validateTokenWorker)
}

export function* authSaga() {
  yield all([fork(validateTokenWatcher)])
}
