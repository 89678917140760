import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

export default function requireAuth(WrappedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
      if (!this.props.isLoggedIn) {
        return <Redirect to="/login" />
      }
      if (this.props.location.pathname === '/' && this.props.uuid) {
        return <Redirect to={`/${this.props.uuid}`} />
      }

      return <WrappedComponent {...this.props} />
    }
  }

  function mapStateToProps({ auth }) {
    return { isLoggedIn: auth.isLoggedIn, uuid: auth.uuid }
  }

  return connect(mapStateToProps)(withRouter(Authentication))
}
