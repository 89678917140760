import React, { Component } from 'react'
import {
  Button,
  Accordion,
  Container,
  Icon,
  Table,
  Form,
} from 'semantic-ui-react'

import styled from 'styled-components'

import wrapCategory from 'components/categories/wrapCategory'
import { green, lightRed } from 'theme.js'
import UploadSoundForm from '../forms/UploadSoundForm'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${green};
    }

    &.active {
      color: white !important;
      background: ${green};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }

  .m-calendar {
    table {
      tr {
        td:first-child {
          border-left: 1px solid #dfe0e4 !important;
        }
      }
      tr:first-child {
        td {
          border-top: 1px solid #dfe0e4 !important;
        }
      }

      td{
        text-align: center;
        padding 8px 0;
      }
    }
  }
`

class Social extends Component {
  handleSubmit = (start, end, acceptedFile) => {
    this.props.onUpdateSound(
      'informing',
      start.toISOString(),
      end.toISOString(),
      acceptedFile[0],
    )
  }

  render() {
    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 1}
          index={1}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            социальное информирование
            <Icon
              name={`angle ${this.props.activeIndex === 1 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 1}>
          <Container style={{ fontSize: '1rem' }}>
            <UploadSoundForm
              data={this.props.data}
              color="green"
              onSubmit={this.handleSubmit}
            ></UploadSoundForm>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default wrapCategory(Social)
