import React, { Component } from 'react'
import {
  Accordion,
  Container,
  Icon,
  Form,
  Input,
  Button,
  Checkbox,
} from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'

import { violet, lightRed } from 'theme.js'
import { connect } from 'react-redux'
import { updateSIPConfig } from 'ducks/deviceActions'
import { Switch, withRouter } from 'react-router-dom'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${violet};
    }

    &.active {
      color: white !important;
      background: ${violet};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`

class SIP extends Component {
  state = {
    server: this.props.data.server,
    username: this.props.data.username,
    login: this.props.data.login,
    pass: this.props.data.pass,
    incoming_call: this.props.data.incoming_call || false,
    call_password: this.props.data.call_password,
    dtmf_open_door: this.props.data.dtmf_open_door,
    isVisible: false,
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  onSubmit = (e) => {
    e.preventDefault()

    const modifiedPass = !/\*+/g.test(this.state.pass)

    let newConfig = {
      id: this.props.match.params.id,
      server: this.state.server,
      username: this.state.username,
      login: this.state.login,
      pass: modifiedPass ? this.state.pass : null,
      call_password: this.state.call_password,
      dtmf_open_door: this.state.dtmf_open_door,
      incoming_call: this.state.incoming_call,
    }

    this.props.updateSIPConfig(newConfig)
  }

  render() {
    const { activeIndex, handleClick } = this.props
    return (
      <Wrapper>
        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={handleClick}
        >
          <Container className="category-title">
            конфигурация SIP
            <Icon name={`angle ${activeIndex === 4 ? 'down' : 'right'}`} />
          </Container>
        </Accordion.Title>

        <Accordion.Content active={activeIndex === 4}>
          <Container>
            <Form onSubmit={this.onSubmit}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Сервер</label>
                  <Input
                    fluid
                    placeholder="sip.sputnik.systems"
                    name="server"
                    value={this.state.server || ''}
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Имя пользователя</label>

                  <Input
                    fluid
                    placeholder="Имя пользователя"
                    value={this.state.username || ''}
                    name="username"
                    onChange={this.onChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Логин</label>

                  <Input
                    fluid
                    placeholder="Логин"
                    value={this.state.login || ''}
                    name="login"
                    onChange={this.onChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Пароль</label>

                  <Input
                    type={this.state.isVisible ? 'text' : 'password'}
                    fluid
                    placeholder={this.state.pass}
                    value={this.state.pass || ''}
                    name="pass"
                    onFocus={() => {
                      if (!this.state.isVisible) {
                        this.setState({ isVisible: true })
                        this.setState({ pass: '' })
                      }
                    }}
                    onChange={(e) => {
                      this.onChange(e)
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Checkbox
                  toggle
                  label="Входящие звонки"
                  color="violet"
                  name="incoming_call"
                  checked={this.state.incoming_call}
                  onChange={(e, { checked }) =>
                    this.setState({ incoming_call: checked })
                  }
                ></Checkbox>
              </Form.Field>
              {this.state.incoming_call && (
                <>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Открытие двери по DTMF</label>
                      <Input
                        fluid
                        name="dtmf_open_door"
                        value={this.state.dtmf_open_door || ''}
                        onChange={this.onChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Пароль для звонка</label>

                      <Input
                        fluid
                        value={this.state.call_password || ''}
                        name="call_password"
                        onChange={this.onChange}
                        type="password"
                      />
                    </Form.Field>
                  </Form.Group>
                </>
              )}
              <div style={{ display: 'flex' }}>
                <Button
                  circular
                  color="violet"
                  // onClick={this.onSubmit}
                  type="submit"
                  style={{
                    marginLeft: 'auto',
                    marginTop: '1.5rem',
                  }}
                >
                  Обновить данные
                </Button>
              </div>
            </Form>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}

export default connect(null, { updateSIPConfig })(withRouter(wrapCategory(SIP)))
