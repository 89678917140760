import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Label, Grid, Form, Segment, Button, Header } from 'semantic-ui-react'
import Slider, { createSliderWithTooltip, Range } from 'rc-slider'
import { useSelector, useDispatch } from 'react-redux'
import { blue } from 'theme'
import 'theme/slider.css'
import { updateGeneralFlatsInfo } from 'ducks/deviceActions'
import useIsMount from 'hooks/useIsMount'

// const RangeWithTooltip = createSliderWithTooltip(Range)
const SliderWithTooltip = createSliderWithTooltip(Slider)

const center = {
  display: 'flex',
  justifyContent: 'center',
}

const getDefaultLevels = commutatorType => {
  switch (commutatorType) {
    // ELTYS
    case 3:
      return [1, 1500, 3270]
    default:
      return [550, 1900, 3270]
  }
}

const defaultValue = 3270

const ResistanceLevels = ({ style = {}, ...props }) => {
  const isMount = useIsMount()
  const dispatch = useDispatch()

  const device = useSelector(({ device }) => device && device.shadowDevice)
  const baseConfig = device && device.properties && device.properties.base
  const [modified, setModified] = useState(false)
  const [level, setLevel] = useState(
    (baseConfig.adc_levels && baseConfig.adc_levels[2]) || defaultValue,
  )
  useEffect(() => {
    if (!isMount) setModified(true)
  }, [level])

  const handleReset = () => setLevel(defaultValue)

  const handleSubmit = () => {
    let newLevels = [...baseConfig.adc_levels]
    newLevels.splice(-1, 1, level)
    dispatch(
      updateGeneralFlatsInfo({
        id: device.device_id,
        adc_levels: newLevels,
      }),
    )
    setModified(false)
  }

  return (
    <Segment
      style={{ display: 'flex', flexDirection: 'column', ...style }}
      {...props}
    >
      <Header>Регулировка уровней событий</Header>

      <Form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {/* <Form.Field>
          <label style={{ display: 'inline' }}>Уровни событий</label>
          <RangeWithTooltip
            range
            trackStyle={{ backgroundColor: blue }}
            min={0}
            max={3250}
            step={1}
            value={levels}
            onChange={setLevel}
            count={3}
            pushable
          />
        </Form.Field>
        <Form.Field>
          <Label size="large" style={{ width: '100%' }}>
            Идет дозвон в трубку квартирную
            <Label.Detail>
              {levels[0]} - {levels[1]} Ом
            </Label.Detail>
          </Label>
        </Form.Field>
        <Form.Field>
          <Label size="large" style={{ marginTop: '.325rem', width: '100%' }}>
            Сняли трубку
            <Label.Detail>
              {levels[1]} - {levels[2]} Ом
            </Label.Detail>
          </Label>
        </Form.Field> */}
        <Form.Field>
          <label style={{ display: 'inline' }}>Дозвон в трубку</label>
          <SliderWithTooltip
            trackStyle={{ backgroundColor: blue }}
            min={3270 - 1000}
            max={3270 + 1000}
            step={1}
            value={level}
            onChange={setLevel}
            // count={3}
            pushable
          />
        </Form.Field>
        <Form.Field>
          <Label size="large" style={{ marginTop: '.325rem' }}>
            {level}+ Ом
          </Label>
        </Form.Field>

        <div style={{ display: 'flex', marginTop: 'auto' }}>
          <Button
            circular
            color="black"
            onClick={handleSubmit}
            className="update-button"
            disabled={!modified}
          >
            Обновить
          </Button>
          <Button
            basic
            icon="erase"
            content="Сбросить"
            circular
            onClick={handleReset}
            // style={{ marginRight: '.625rem' }}
          />
        </div>
      </Form>
    </Segment>
  )
}

// const mapStateToProps = ({ device }) => ({
//   data:
//     device &&
//     device.shadowDevice &&
//     device.shadowDevice.properties &&
//     device.shadowDevice.properties.base,
//   id: device && device.shadowDevice && device.shadowDevice.device_id,
// })

// export default connect(mapStateToProps, { updateGeneralFlatsInfo })(
//   FlatsGeneral,
// )

export default ResistanceLevels
